import ApplicationController from "./application_controller.ts"
import xCircle from "heroicons/solid/x-circle.svg"
import menu from "heroicons/solid/menu.svg"

export default class extends ApplicationController {
  
  static targets = [ 'menuIcon', 'menu', 'logo' ]

  logoTarget: HTMLImageElement
  menuIconTarget: HTMLElement
  menuTarget: HTMLDivElement
  navLinks: NodeListOf<HTMLLinkElement>
  url: URL

  // svgCross: string = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x-circle"><circle cx="12" cy="12" r="10"></circle><line x1="15" y1="9" x2="9" y2="15"></line><line x1="9" y1="9" x2="15" y2="15"></line></svg>`
  // svgBurger: string = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-menu"><line x1="3" y1="12" x2="21" y2="12"></line><line x1="3" y1="6" x2="21" y2="6"></line><line x1="3" y1="18" x2="21" y2="18"></line></svg>`

  connect () {
    this.navLinks = this.element.querySelectorAll('.nav-link')
    this.menuIconTarget.innerHTML = this.svg(menu, { height: 24, width: 24 })
    this.url = new URL(window.location.href)
    this.highlightNavLinks()
    // document.addEventListener("scroll", (e) => {
    //   /*Apply classes for slide in bar*/
    //   let scrollpos : Number = window.scrollY
    //   if (scrollpos > 10) {
    //     this.element.classList.remove("normal", "gradient")
    //     this.element.classList.add("inverted")
    //     this.toggleNavLinks();
    //   } else {
    //     this.element.classList.remove("inverted")
    //     this.element.classList.add("normal", "gradient")
    //     this.toggleNavLinks();
    //   }
    // })
  }

  toggleNavLinks() {
    for (var i = 0; i < this.navLinks.length; i++) {
      if (this.element.classList.contains("inverted")) {
        this.navLinks[i].classList.add("inverted")
        this.navLinks[i].classList.remove("normal")
      } else {
        this.navLinks[i].classList.add("normal")
        this.navLinks[i].classList.remove("inverted")
      }
    }
  }

  highlightNavLinks() {
    this.navLinks.forEach((link) => {
      let re = new RegExp(`^${link.href}`)
      if (this.url.href.match(re)) {
        // console.log("matched " + link.href)
        link.classList.add("bg-link")
      } else {
        link.classList.remove("bg-link")
      }
    })
  }

  toggleMenu(event) {
    this.menuTarget.classList.toggle("hidden")

    if (this.menuTarget.classList.contains("hidden")) {
      this.menuIconTarget.innerHTML = this.svg(menu, { height: 24, width: 24 })
    } else {
      this.menuIconTarget.innerHTML = this.svg(xCircle, { height: 24, width: 24 })
    }
    for (var i = 0; i < this.navLinks.length; i++) {
      this.navLinks[i].classList.toggle("ringed")
    }
  }


}