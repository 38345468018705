import { Controller } from 'stimulus'

export default class ApplicationController extends Controller {

  svg(svg_string,attrs = null) {
    const parser = new DOMParser()
    const svgImage = parser.parseFromString(svg_string, "image/svg+xml").documentElement
    if (attrs) {
      for (const [key, value] of Object.entries(attrs)) {
        // console.log(`key: ${key}`)
        // console.log(`value: ${value}`)
        svgImage.setAttribute(key.toString(),value.toString())
      }
    }
    return svgImage.outerHTML
  }
}